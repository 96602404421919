import { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { StatusApiService } from '../../../api/statusapi.service';
import { Globals } from '../../../global';
import { Incident, Region, StatusPageModel, Zone } from '../../../models/StatusPageModel';

@Component({
  selector: 'app-home4',
  templateUrl: './home4.component.html',
  styleUrls: ['./home4.component.css']
})
export class Home4Component implements OnInit {

  statusData: StatusPageModel;
  zones: Zone[];
  regions: Region[];
  activeAnnouncements: Incident[];
  refreshClock: any;
  incidents: Incident[];
  maintenances: Incident[];

  $currentDateTime: Observable<Date> = timer(0, 1000).pipe(map(() => new Date()));
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  toolTipProps = { placement: 'bottom', theme: 'light', allowHTML: true };
  toolTipDarkProps = { placement: 'bottom-start', allowHTML: true };

  tipcontent: string = "<div class='p-2'><label class = 'bg-light'><i class='bi bi-check-lg'> </i><span class='' > Operational </span>";
  tipcontentError: string = "<div class='p-3'><label>15 Dec 2021</label><br/><label class = 'mt-3 bg-light'><i class='bi bi-x-lg text-danger me-2'> </i><span class='me-3' > Partial Outage </span>";

  dtOptions: DataTables.Settings = Globals.homeDtOptions;
  refreshOptions: Array<Object> = [
    { name: '2 Minutes', value: "2" },
    { name: '5 Minutes', value: "5"  },
    { name: '10 Minutes', value: "10" }
  ]
  refreshInterval: string = "2";
  timeZone;

  constructor(private _statusApiService: StatusApiService) {
    this.timeZone = Globals.timeZone;
  }

  ngOnInit(): void {
    this.getComponentStatus(true);
    this.getActiveAnnouncments();
    this.setUpdateRefreshClock();
  }

  search(event: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(event.target.value).draw();
    });
  }

  getComponentStatus(updateRegionZones = false) {
    this._statusApiService.GetComponentsStatus()
      .subscribe(
        data => {
          if (data && data.success && data.result && data.result.componentGroups
            && data.result.componentGroups.length > 0) {
            this.statusData = data.result;
            this.incidents = this.statusData.incidents;
            this.maintenances = this.statusData.maintenances;

            if (updateRegionZones)
              this.upadteZonesFromStatusData();
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  upadteZonesFromStatusData() {
    let totalZones: Zone[] = [];
    this.statusData.regions.forEach((r) => {
      r.zones.forEach((z) => {
        let zone: Zone = {
          regionId: r.regionId,
          regionName: r.regionName,
          sequence: z.sequence,
          zoneId: z.zoneId,
          zoneName: z.zoneName
        };
        totalZones.push(zone);
      });
    });
    this.zones = totalZones;
    this.regions = this.statusData.regions;
  }

  refreshRateChange() {
    this.setUpdateRefreshClock();
  }

  setUpdateRefreshClock() {
    if (this.refreshClock)
      clearInterval(this.refreshClock);

    this.refreshClock = setInterval(() => {
      //console.log('executed : ' + this.refreshInterval + " " + new Date());
      this.getComponentStatus();
    }, parseInt(this.refreshInterval) * 1000 * 60);
  }

  getActiveAnnouncments() {
    this._statusApiService.GetActiveAnnouncements()
      .subscribe(
        data => {
          if (data && data.success && data.result) {
            this.activeAnnouncements = data.result;
          }
        },
        error => {
          console.log(error);
        }
      );
  }

}
