import { filter } from 'rxjs/operators';
import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { ChangeDetectorRef, Component, OnInit, Pipe, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { DataTableDirective } from "angular-datatables";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { Subject, pipe } from "rxjs";
import { AdminApiService } from "src/app/api/adminapi.service";
import { DataSharingService } from "src/app/api/data-sharing.service";
import { UserService } from "src/app/api/user.service";
import { AppComponent } from "src/app/app.component";
import { CGRZChecklistDatabase } from "src/app/models/CGRZCheckListDatabase";
import { CGItemFlatNode, CGItemNode, ComponentDetail } from "src/app/models/treeview";
import { map } from 'jquery';

@Component({
  selector: "app-incident",
  templateUrl: "./incident.component.html",
  styleUrls: ["./incident.component.css"],
})

export class IncidentComponent implements OnInit {

  displayModal = false;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: any = DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  reportIncidentData;
  item:any = {};
  data:any = {};
  statusOptions: Array<Object> = [{ name: 'Select', value: '' }];
  typeOptions: Array<Object> = [{ name: 'Select', value: '' }];
  sourceOptions: Array<Object> = [{ name: 'Select', value: '' }];
  subTypeOptions: Array<Object> = [{ name: 'Select', value: '' }];
  affectedComponents = [];
  cgregionZones = [];
  dataa : any[];
  isNew = true;
  isValidFormSubmitted = null;
  incidentSource :any = 'Customer Reported'
  casesForm = new UntypedFormGroup({
    incidentId: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
    incidentSubType: new UntypedFormControl('',[Validators.required]),
    incidentSource: new UntypedFormControl('',[Validators.required]),
    incidentType: new UntypedFormControl('Incident', [Validators.required]),
    isActive: new UntypedFormControl(true, [Validators.required]),
  });
  cmp: any[];
  FillCmp :any[];

  constructor(
    private _adminApiService: AdminApiService,
    private toastr: ToastrService,
    private _userService: UserService,
    private dataSharingService: DataSharingService,
    private dialog: MatDialog,
    private cgrzdb: CGRZChecklistDatabase,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder

  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<CGItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.cgrzdb.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });

  }

 ngOnInit() {
  this.getEditableComponents();
  this.getIncidentsMetadata();
  this.getAllComponentRegionsAndZones();
  this.setModel();

    //await this._userService.GetUser();
    //this.isWrite = this._userService.GetUserPermission("write");
    this.dtOptions = {
      lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, "All"],
      ],
      ordering: false,
      pageLength: 10,
      processing: true,
      destroy: true
    };
    this.ReportIncident();
    //this.isNew = false;
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  reload(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.dtTrigger.next();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ReportIncident() {

    this._adminApiService.GetReportIncident().subscribe(
      (data) => {
        if (data && data.success) {
          this.reportIncidentData = data.result;
          this.cmp = data.result
          .flatMap(item => item.components) // Flatten the arrays
          .map(component => component.componentName)

          this.dtTrigger.next();
          this.reload;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
   extractFileName(fullPath: string): string {
    if (!fullPath) {
      return ''; // or any default value you want to use
    }

    const parts = fullPath.split('/').pop(); // Adjust the delimiter based on your path
    return parts[parts.length - 1];

  }

  openModalWithData(item){
    // debugger;
   // if(!this.f.incidentId.value){
    //this.displayStyle = "block";
    const requestPayload = this.prepareRequestModel(item);
    // console.log(item)
    this.isValidFormSubmitted = false;
   // if (item.reportId) {
    if (this.isNew && !this.f.incidentId.value) {
      this._adminApiService.AddIncident(requestPayload)
        .subscribe(
          data => {
            this.toastr.success(" Created Successfully", "Success", { closeButton: true });
            if (data.success && data.result) {
              //item.incidentId.setValue(data.result);
              this.f.incidentId.setValue(data.result);
            }
            this.reset();
            //this.setModel();
            this.displayStyle = "none";
            //this.setModel();

            //this.router.navigate(['/admin/events']);
          },
          error => {
            if (error && error.error && error.error.message && error.error?.result)
              this.toastr.error(error.error.message + " " + error.error?.result[0], "Error", { closeButton: true });
            else if (error && error.error && error.error.message)
              this.toastr.error(error.error.message, "Error", { closeButton: true });
            else
              this.toastr.error("Something Went Wrong. Please contact Admin", "Error", { closeButton: true });

            AppComponent.hideLoader();
            this.displayStyle = "block";
          }
        );
  }

  }

  displayStyle = "none";

  openPopup(item) {
    this.item = item;
    this.FillCmp = item.components;
    this.getReportIncident(this.item);
    // console.log(item.components);
    this.setComponentSelection(this.FillCmp)
    // this.disable();
    this.displayStyle = "block";
  }

  closePopup() {
    this.displayStyle = 'none';
    // this.casesForm.reset();
    this.checklistSelection = new SelectionModel<CGItemFlatNode>(true, []);
  }
  get f() {
    return this.casesForm.controls;
  }

  setModel() {
    AppComponent.showLoader();
    this._adminApiService.GetReportIncident()
      .subscribe(
        data => {
          if (data.success && data.result) {
            console.log(data.result);
            this.cmp = data.result
            .flatMap(item => item.components); // Extract componentName
          console.log(this.cmp);
            // this.dataa = data.result.map(item => item.components);

            // //const component = this.dataa.map(item => item.component);
            // console.log(this.dataa);
            // this.cmp = this.dataa.map(a => a.componentName);
            // console.log(this.cmp);

          }
          AppComponent.hideLoader();
        },
        error => {
          if (error && error.error && error.error.message)
            this.toastr.error(error.error.message, "Error", { closeButton: true });
          else
            this.toastr.error("Something Went Wrong. Please contact Admin", "Error", { closeButton: true });

          AppComponent.hideLoader();
        }
      );
  }
  disable(){
    if(this.f.incidentSubType.value == "Partial Outage"){
      this.f.incidentSubType.disable();
    }
    if(this.f.incidentType.value == "Incident"){
      this.f.incidentType.disable();
    }
  }


  prepareRequestModel(item) {
      this.data = item;
  const reequestModel = <any>{};
  if(this.f.incidentId.value)
  reequestModel.incidentId =this.f.incidentId.value;
  //reequestModel.incidentId =item.ReportId;
  reequestModel.title = item.subject;
  reequestModel.description = item.description;
  reequestModel.startDate = item.createdDate;
  //reequestModel.type = item.subject ;
  reequestModel.status = this.f.status.value;
  reequestModel.source = item.description;
  reequestModel.type = this.f.incidentType.value;
  reequestModel.source = this.f.incidentSource.value;
  reequestModel.isActive = this.f.isActive.value;
  reequestModel.affectedComponents = this.getSeletedComponents();
  reequestModel.componentStatus = this.f.incidentSubType.value;
  reequestModel.subType = this.f.incidentSubType.value;
  reequestModel.incidentSubType = this.f.incidentSubType.value;
//   if (this.f.startDate.value._d)
//   reequestModel.startDate = this.f.startDate.value.format('MMM/DD/yyyy HH:mm:ss A');
// else
//   reequestModel.startDate = moment(this.f.startDate.value).format('MMM/DD/yyyy HH:mm:ss A');
 // reequestModel.isActive = item.isActive;
  //reequestModel.isPublic = this.f.isPublic.value;
// this.getReportIncident(item);
  return reequestModel;
}


getReportIncident(item) {
  console.log("hello",item);
  this.f.incidentSubType.setValue(item.status);
// debugger;
//   if(item.status == "partial outage")
//   {
//     this.f.incidentSubType.disable();
//   }

}




getEditableComponents() {
  return (
    // this.f.incidentType.value.includes('Announcement') ||
    // (this.f.incidentType.value == 'Maintenance') ||
    (this.f.incidentType.value == 'Incident')
  );
}

getIncidentsMetadata() {
  this._adminApiService.GetIncidentMetadata()
    .subscribe(
      data => {
        if (data && data.success) {

          if (data.result && data.result.incidentTypeList && data.result.incidentTypeList.length > 0) {

            data.result.incidentTypeList.forEach((item) => {
                this.typeOptions.push({ name: item, value: item });
            });
          }

          if (data.result && data.result.incidentStatusList && data.result.incidentStatusList.length > 0) {
            this.statusOptions = [{ name: 'Select', value: '' }];

            data.result.incidentStatusList.forEach((item) => {
              this.statusOptions.push({ name: item, value: item });
            });
          }

          if (data.result && data.result.sourceTypesList && data.result.sourceTypesList.length > 0) {
            this.sourceOptions = [{ name: 'Select', value: '' }];

            data.result.sourceTypesList.forEach((item) => {
              this.sourceOptions.push({ name: item, value: item });
            });
          }

          if (data.result && data.result.incidentSubTypesList && data.result.incidentSubTypesList.length > 0) {

            data.result.incidentSubTypesList.forEach((item) => {
              this.subTypeOptions.push({ name: item, value: item });
            });
          }
        }
      },
      error => {
        console.log(error);
      }
    );
}
save(){
this.openModalWithData(this.item);
}

reset(){
  this.f.incidentId.setValue('');
  this.f.status.setValue('');
  this.f.incidentSubType.setValue('');
  this.f.incidentSource.setValue('');
  this.f.incidentType.setValue('');
  this.f.isActive.setValue(false);
}



getSeletedComponents() {
  let itemNode: ComponentDetail[] = [];
  this.checklistSelection.selected.forEach((item) => {

    let component = itemNode?.filter(x => x.componentId == item.item.componentId
      && x.regionId == item.item.regionId && x.zoneId == item.item.zoneId);

    if (item.level == 2 && (component == null || component?.length <= 0)) {
      let componentDetail = new ComponentDetail();
      componentDetail.componentId = item.item.componentId;
      componentDetail.componentName = item.item.componentName;
      componentDetail.regionId = item.item.regionId;
      componentDetail.regionName = item.item.regionName;
      componentDetail.zoneId = item.item.zoneId;
      componentDetail.zoneName = item.item.zoneName;
      itemNode.push(componentDetail);
    }
  });
  return itemNode;
}

getAllComponentRegionsAndZones() {
  this._adminApiService.GetAllComponentGroupsWithRegionAndZone()
    .subscribe(
      data => {
        if (data && data.success && data.result) {

          if (data.result && data.result.length > 0) {
            this.cgregionZones = data.result;
            this.cgrzdb.initialize(this.cgregionZones);
            //this.treeControl.expandAll();

            this.cdRef.detectChanges();

          }
        }
      },
      error => {
        console.log(error);
      }
    );
}



flatNodeMap = new Map<CGItemFlatNode, CGItemNode>();
nestedNodeMap = new Map<CGItemNode, CGItemFlatNode>();
selectedParent: CGItemFlatNode | null = null;
treeControl: FlatTreeControl<CGItemFlatNode>;
treeFlattener: MatTreeFlattener<CGItemNode, CGItemFlatNode>;
dataSource: MatTreeFlatDataSource<CGItemNode, CGItemFlatNode>;
checklistSelection = new SelectionModel<CGItemFlatNode>(true);

getLevel = (node: CGItemFlatNode) => node.level;
isExpandable = (node: CGItemFlatNode) => node.expandable;
getChildren = (node: CGItemNode): CGItemNode[] => node.children;
hasChild = (_: number, _nodeData: CGItemFlatNode) => _nodeData.expandable;
hasNoContent = (_: number, _nodeData: CGItemFlatNode) => !_nodeData.item;

/**
 * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
 */
transformer = (node: CGItemNode, level: number) => {
  const existingNode = this.nestedNodeMap.get(node);
  const flatNode =
    existingNode && existingNode.item === node.item ? existingNode : new CGItemFlatNode();
  flatNode.item = node.item;
  flatNode.level = level;
  flatNode.expandable = !!node.children?.length;
  this.flatNodeMap.set(flatNode, node);
  this.nestedNodeMap.set(node, flatNode);
  return flatNode;
};

/** Whether all the descendants of the node are selected. */
descendantsAllSelected(node: CGItemFlatNode): boolean {
  const descendants = this.treeControl.getDescendants(node);
  const descAllSelected =
    descendants.length > 0 &&
    descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
  return descAllSelected;
}

/** Whether part of the descendants are selected */
descendantsPartiallySelected(node: CGItemFlatNode): boolean {
  const descendants = this.treeControl.getDescendants(node);
  const result = descendants.some(child => this.checklistSelection.isSelected(child));
  return result && !this.descendantsAllSelected(node);
}

/** Toggle the to-do item selection. Select/deselect all the descendants node */
todoItemSelectionToggle(node: CGItemFlatNode): void {
  this.checklistSelection.toggle(node);
  const descendants = this.treeControl.getDescendants(node);
  this.checklistSelection.isSelected(node)
    ? this.checklistSelection.select(...descendants)
    : this.checklistSelection.deselect(...descendants);

  // Force update for the parent
  descendants.forEach(child => this.checklistSelection.isSelected(child));
  this.checkAllParentsSelection(node);
}

/** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
todoLeafItemSelectionToggle(node: CGItemFlatNode): void {
  this.checklistSelection.toggle(node);
  this.checkAllParentsSelection(node);
}

/* Checks all the parents when a leaf node is selected/unselected */
checkAllParentsSelection(node: CGItemFlatNode): void {
  let parent: CGItemFlatNode | null = this.getParentNode(node);
  while (parent) {
    this.checkRootNodeSelection(parent);
    parent = this.getParentNode(parent);
  }
}

/** Check root node checked state and change it accordingly */
checkRootNodeSelection(node: CGItemFlatNode): void {
  const nodeSelected = this.checklistSelection.isSelected(node);
  const descendants = this.treeControl.getDescendants(node);
  const descAllSelected =
    descendants.length > 0 &&
    descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
  if (nodeSelected && !descAllSelected) {
    this.checklistSelection.deselect(node);
  } else if (!nodeSelected && descAllSelected) {
    this.checklistSelection.select(node);
  }
  this.getSeletedComponents();
}

/* Get the parent node of a node */
getParentNode(node: CGItemFlatNode): CGItemFlatNode | null {
  const currentLevel = this.getLevel(node);

  if (currentLevel < 1) {
    return null;
  }

  const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

  for (let i = startIndex; i >= 0; i--) {
    const currentNode = this.treeControl.dataNodes[i];

    if (this.getLevel(currentNode) < currentLevel) {
      return currentNode;
    }
  }
  return null;
}


setComponentSelection(components) {
  if (components) {
    components.forEach((component) => {
      this.treeControl.dataNodes.forEach((c) => {
        if (c.item.componentId == component.componentId) {
          this.checklistSelection.select(c);

          if (c.level == 2) {
            let parentNode = this.getParentNode(c);
            this.treeControl.expand(this.getParentNode(parentNode));
            this.treeControl.expand(parentNode)
          }
        }
      });
    });
  }
}
}

