import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { data } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { AdminApiService } from 'src/app/api/adminapi.service';
import { AuthenticationService } from 'src/app/api/authentication.service';
import { sharedService } from 'src/app/api/sharedService';
import { StatusApiService } from 'src/app/api/statusapi.service';
import { UserService } from 'src/app/api/user.service';
import { AppComponent } from 'src/app/app.component';
import { user } from 'src/app/models/UserModel';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  logoUrl: string;
  title: any;
  //email: string;
  password: string;
  signInForm: FormGroup;

  isValidFormSubmitted = null;

  userModel: user = { usernameOrEmail: '', password: '' };

  SignInForm = new UntypedFormGroup({
    Username: new UntypedFormControl(''),
    Password: new UntypedFormControl(''),
  });

  constructor(private _statusApiService: StatusApiService,
    private adminApiService: AdminApiService,
    private userService:UserService,
    public router: Router,
    private _sharedService: sharedService,
    private toastr: ToastrService,
    private authenticationService:AuthenticationService
    ) {

      this.signInForm = new FormGroup({
        usernameOrEmail: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
      });
  }
  ngOnInit(): void {
    if (this.authenticationService.isLoggedIn) {
      this.router.navigate(['/admin/events']);
    }
    //this.checkValidation();
    this._sharedService.getSetting().subscribe((logoPath) => {
      this.logoUrl = logoPath;
    });
      this.adminApiService.GetSettings().subscribe((data) => {
        const details = data.result[0]?.title;
         this.title = details;
      });
    }

    get f() {
      return this.SignInForm.controls;
    }
    
  login() {

    this.isValidFormSubmitted = false;
    if (this.SignInForm.invalid) {
      this.toastr.error("Please fix validation errors to continue", "Error", { closeButton: true });
      AppComponent.hideLoader();
      return;
    }
    this.isValidFormSubmitted = true;

    AppComponent.showLoader();

    // this.adminApiService.login(this.userModel).subscribe(
    //   data => {
    //     if(data.success)
    //     {
    //       this.toastr.success("SignIn Successfully", "Success", { closeButton: true });
    //       this.router.navigate(['/admin']);
    //       AppComponent.hideLoader();
    //     }

    //   },
    //   error => {
    //     if (error && error.error && error.error.message)
    //       this.toastr.error(error.error.message, "Error", { closeButton: true });
    //     else
    //       this.toastr.error("Something Went Wrong. Please contact Admin", "Error", { closeButton: true });

    //     AppComponent.hideLoader();
    //   }
    // );
    // }
    this.authenticationService.login(this.userModel).subscribe(
      (data) => {
        if(data.success){
          this.toastr.success("SignIn Successfully", "Success", { closeButton: true });
           this.router.navigate(['/admin']);
           AppComponent.hideLoader();
        }
      },
        error => {
          if (error && error.error && error.error.message)
            this.toastr.error(error.error.message, "Error", { closeButton: true });
          else
            this.toastr.error("Something Went Wrong. Please contact Admin", "Error", { closeButton: true });

          AppComponent.hideLoader();
        }
    );
      }
    // checkValidation() {
    //   this.f.username.setValidators([Validators.required]);
    //   this.f.password.setValidators([Validators.required]);
    // }




}


