import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminApiService } from 'src/app/api/adminapi.service';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-ping',
  templateUrl: './uptime.component.html',
  styleUrls: ['./uptime.component.css']
})
export class uptimeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  uptimelistData: any = [];

  constructor(private _adminApiService: AdminApiService,
              private toastr: ToastrService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.dtOptions = {
      lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, 'All']
      ],
      ordering: false,
      pageLength: 10
    };
    this.getUptimeList();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  getUptimeList(): void {
    this._adminApiService.getuptime().subscribe((data) => {
      this.uptimelistData = data.result;
      this.reloadDataTable();
    });
  }

  reloadDataTable(): void {
    if (this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }
  }

  uptimedeleteEvent(uptimeTestId): void {
    this.openDeleteEventDialog(uptimeTestId);
  }

  deleteEventCall(uptimeTestId): void {
    AppComponent.showLoader();
    if (uptimeTestId) {
      this._adminApiService.DeleteUptime(uptimeTestId)
        .subscribe(
          () => {
            this.toastr.success('Deleted Successfully', 'Success', { closeButton: true });
            AppComponent.hideLoader();
            this.getUptimeList();
          },
          (error) => {
            let errorMessage = 'Something Went Wrong. Please contact Admin';
            if (error.error && error.error.message) {
              errorMessage = error.error.message;
            }
            this.toastr.error(errorMessage, 'Error', { closeButton: true });
            AppComponent.hideLoader();
          }
        );
    }
  }

  openDeleteEventDialog(eventId): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure want to delete?',
        eventId,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'full-dialog-container',
      maxWidth: '70vw'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.isDelete) {
        this.deleteEventCall(result.eventId);
      }
    });
  }
}
