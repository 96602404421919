import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Globals } from '../global';
import { User } from '../models/UserModel';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  apiURL = Globals.apiURL;
  cookieKey = 'loggedInUser';
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);


  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.GetUser();
  }

  async GetUser() {
    let user = this.cookieService.get(this.cookieKey);
    if (!user) {
      await this.GetUserFromServer();
      user = this.cookieService.get(this.cookieKey);
    }

    return user;
  }

 GetAlluser(){
    return this.http.get<any>(this.apiURL + 'admin/user/getall');
  }
  UpdateroleAndisActiveuser(params){
    return this.http.put<any>(this.apiURL + 'admin/user/roleAndisActive',params);
  }
  GetAllSearchUser(params) {
    return this.http.post<any>(this.apiURL + 'admin/user/list', params);
  }

  set user(value: User) {
    this.removeUser();
    this.cookieService.set(this.cookieKey, JSON.stringify(value),1,'/');

    this._user.next(value);
  }

  get user$(): Observable<User> {
    let cookieUser = this.cookieService.get(this.cookieKey);
    if (cookieUser)
      this.user = JSON.parse(cookieUser);

    return this._user.asObservable();
  }

  GetUserFromServer() {
    let promise = new Promise<void>((resolve, reject) => {

    this.http.get<any>(this.apiURL + 'admin/user').subscribe(
      data => {
        this.user = data.result;
        resolve();
      },
      error => {
        resolve();
      }
      );
    });
    return promise;
  }

  removeUser() {
    this.cookieService.delete(this.cookieKey,'/');
  }

  GetUserPermission(type: string) {
    let hasTypePermission: boolean = false;
    this.user$
      .subscribe((user: User) => {
        let userRoles: string[] = [];
        if (user.role == "Admin") {
          userRoles.push("write");
          userRoles.push("manage");
          userRoles.push("admin");
        }
        else if (user.role == "ReadWriteOnly") {
          userRoles.push("write");
        }
        else if (user.role == "ReadOnly") {
          userRoles.push("read"); 
        }
        if (userRoles.includes(type))
          hasTypePermission = true;
      });

    return hasTypePermission;
  }
}
