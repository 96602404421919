import { Component, OnInit, ViewChild} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AdminApiService } from '../../../api/adminapi.service';
import { AppComponent } from '../../../app.component';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '../../../global';
import { UserService } from '../../../api/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  componentCount: any;
  componentGroupCount: any;
  incidentCount: any;
  subscriberCount: any;
  incidentCountByType: any;
  incidentCountByTypeAnnounce: any;
  Count:any;
  // @ViewChild(DataTableDirective, { static: false })
  // datatableElement: any = DataTableDirective;

  // displayDeleted: boolean = true;
  // canvas: any;
  // ctx: any;
  // isWrite: boolean = false;
  // timeZone;
  // incidentList;
  // status: string = '';
  // type: string = '';
  // // dtOptions: DataTables.Settings = {};
  // dtTrigger: Subject<any> = new Subject<any>();

  // incidentsByUptimeChart: any;
  // chartColors: any = ["#00FF00", "#d67f3c", "#cd4a45", "#0078d4", "#88a362", "#d07f9c"];

  // constructor(private _adminApiService: AdminApiService, private _userService: UserService) {
  // }

  // async ngOnInit() {
  //   await this._userService.GetUser();
  //   this.isWrite = this._userService.GetUserPermission("write");
  //   // this.dtOptions = {
  //   //   lengthMenu: [
  //   //     [10, 25, 50, 100, -1],
  //   //     [10, 25, 50, 100, "All"]
  //   //   ],
  //   //   ordering: false,
  //   //   pageLength: 10
  //   // };

  //   this.getAllIncidents();

  // }
  // ngOnDestroy(): void {
  //   this.dtTrigger.unsubscribe();
  // }
  // prepareIncidentRequestModel() {
  //   const incidentModel = <any>{};
  //   incidentModel.status = this.status;
  //   incidentModel.type = this.type;
  //   incidentModel.isActive = this.displayDeleted;

  //   return incidentModel;
  // }
  // reload(): void {
  //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     dtInstance.destroy();
  //   });
  //   this.dtTrigger.next();
  // }
  // ngAfterViewInit() {
  //   this.dtTrigger.next();
  // }
  // getAllIncidents() {
  //   const requestPayload = this.prepareIncidentRequestModel();

  //   this._adminApiService.GetAllIncidents(requestPayload)
  //     .subscribe(
  //       data => {
  //         if (data && data.success && data.result) {
  //           this.incidentList = data.result;

  //           this.reload();
  //         }
  //       },
  //       error => {
  //         console.log(error);
  //       }
  //     );
  // }
  // isWriteRole() {
  //   return this.isWrite;
  // }
  // fillIncidentsByUptimeChart(id, data, title, percentage = false) {
  //   this.canvas = document.getElementById(id);
  //   this.ctx = this.canvas.getContext('2d');
  //   if (this.incidentsByUptimeChart != null) {
  //     this.incidentsByUptimeChart.destroy();
  //   }
  //   this.incidentsByUptimeChart = this.getChart(id, data, title, percentage);
  // }
  // getChart(id, data, title, percentage) {
  //   return new Chart(this.ctx, {
  //     type: 'pie',
  //     data: {
  //       labels: data.map(x => x.type),
  //       datasets: [{
  //         label: title,
  //         data: percentage ? data.map(x => x.percentage) : data.map(x => x.typeCount),
  //         backgroundColor: this.chartColors,
  //         borderWidth: 1
  //       }],
  //     },
  //     options: {

  //       plugins: {
  //         title: {
  //           display: true,
  //           text: title
  //         },
  //         legend: {
  //           position: 'bottom',
  //           onClick: null
  //         },
  //         datalabels: {
  //           align: 'start',
  //           font: {
  //             weight: 'bold',
  //             size: 12
  //           },
  //           formatter: (value, ctx) => {
  //             if (percentage) {
  //               return value > 5 ? value.toFixed(2) + "%" : "";
  //             }
  //             else {
  //               return value;
  //             }
  //           },
  //           color: '#fff',
  //           display: 'auto'
  //         },
  //         tooltip: {
  //           callbacks: {
  //             label: function (tooltipItem) {
  //               if (percentage)
  //                 return tooltipItem.label + " : " + Number(tooltipItem.raw).toFixed(2) + "%";
  //               else {
  //                 let dataSetDetails = tooltipItem.dataset.data as number[];
  //                 let total = dataSetDetails.reduce(function (previousValue, currentValue, currentIndex, array) {
  //                   return previousValue + currentValue;
  //                 });
  //                 let currentValue = tooltipItem.dataset.data[tooltipItem.dataIndex] as number;
  //                 let calcPercentage = (currentValue * 100 / total).toFixed(2) + "%";

  //                 return tooltipItem.label + " : " + currentValue + " (" + calcPercentage + ") ";
  //               }
  //             }
  //           }
  //         }
  //       }
  //     },
  //     plugins: [ChartDataLabels]
  //   });
  // }
  
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: any = DataTableDirective;

  displayDeleted: boolean = true;
  isWrite: boolean = false;
  status: string = '';
  type: string = '';
  statusOptions: Array<Object> = [{ name: 'All', value: '' }];
  typeOptions: Array<Object> = [{ name: 'All', value: '' }]
  timeZone;
  incidentList;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dateControl = new FormControl(new Date());
 
  constructor(private _adminApiService: AdminApiService, private toastr: ToastrService
    , private _userService: UserService, private dialog: MatDialog) {
    this.timeZone= Globals.timeZone;
   }

  async ngOnInit() {
    await this._userService.GetUser();
    this.isWrite = this._userService.GetUserPermission("write");
    this.dtOptions = {
      lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, "All"]
      ],
      ordering: false,
      pageLength: 10
    };

    this.getAllIncidents();
    this.getIncidentsMetadata();
    this.getComponentCount();
    // this.getComponentGroupCount();
    // this.getIncidentCount();
    // this.getSubscriberCount();
    // this.getIncidentCountByType();
    // this.getIncidentCountByTypeAnnounce();
  }
  
  getComponentCount() {
    this._adminApiService.allDashboard().subscribe(
      (response: any) => {
        this.Count = response.result;
      },
      (error: any) => {
        console.error('Error fetching component count:', error);
      }
    );
  }
  // getComponentGroupCount() {
  //   this._adminApiService.getComponentGroupCount().subscribe(
  //     (response: any) => {
  //       this.componentGroupCount = response.result;
  //     },
  //     (error: any) => {
  //       console.error('Error fetching component count:', error);
  //     }
  //   );
  // }
  // getIncidentCount() {
  //   this._adminApiService.getIncidentCount().subscribe(
  //     (response: any) => {
  //       this.incidentCount = response.result;
  //     },
  //     (error: any) => {
  //       console.error('Error fetching component count:', error);
  //     }
  //   );
  // }
  // getSubscriberCount() {
  //   this._adminApiService.getSubscriberCount().subscribe(
  //     (response: any) => {
  //       this.subscriberCount = response.result;
  //     },
  //     (error: any) => {
  //       console.error('Error fetching component count:', error);
  //     }
  //   );
  // }
  // getIncidentCountByType() {
  //   this._adminApiService.getIncidentCountByType().subscribe(
  //     (response: any) => {
  //       this.incidentCountByType = response.result;
  //     },
  //     (error: any) => {
  //       console.error('Error fetching component count:', error);
  //     }
  //   );
  // }
  // getIncidentCountByTypeAnnounce() {
  //   this._adminApiService.getIncidentCountByTypeAnnounce().subscribe(
  //     (response: any) => {
  //       this.incidentCountByTypeAnnounce = response.result;
  //     },
  //     (error: any) => {
  //       console.error('Error fetching component count:', error);
  //     }
  //   );
  // }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  onDropdownChange() {
    this.getAllIncidents();
  }

  reload(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.dtTrigger.next();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  prepareIncidentRequestModel() {
    const incidentModel = <any>{};
    incidentModel.status = this.status;
    incidentModel.type = this.type;
    incidentModel.isActive = this.displayDeleted;

    return incidentModel;
  }

  getAllIncidents() {
    const requestPayload = this.prepareIncidentRequestModel();

    this._adminApiService.GetAllIncidents(requestPayload)
      .subscribe(
        data => {
          if (data && data.success && data.result) {
            this.incidentList = data.result;
            this.reload();
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  getIncidentsMetadata() {
    this._adminApiService.GetIncidentMetadata()
      .subscribe(
        data => {
          if (data && data.success) {

            if (data.result && data.result.incidentTypeList && data.result.incidentTypeList.length > 0) {
              this.typeOptions = [{ name: 'All', value: '' }];

              data.result.incidentTypeList.forEach((item) => {
                this.typeOptions.push({ name: item, value: item });
              });
            }

            if (data.result && data.result.incidentStatusList && data.result.incidentStatusList.length > 0) {
              this.statusOptions = [{ name: 'All', value: '' }];

              data.result.incidentStatusList.forEach((item) => {
                this.statusOptions.push({ name: item, value: item });
              });
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  copyLink(type, id) {
    let link = "/admin/events/event-detail/" + type + "/" + id;
    AppComponent.copyLink(link);
    this.toastr.success("Link Copied Successfully to clipboard.", "Copied", { closeButton: true });
  }

  checkEndDateExist(date) {
    return new Date(date) > new Date(2000, 1, 1);
  }

  isWriteRole() {
    return this.isWrite;
  }

  deleteEvent(title, type, eventId) {
    this.openDeleteEventDialog(title, type, eventId);
  }

  deleteEventCall(incidentId, type, title) {
    const requestPayload = <any>{};
    requestPayload.incidentId = incidentId;

    AppComponent.showLoader();
    if (incidentId) {
      this._adminApiService.DeleteIncident(requestPayload)
        .subscribe(
          data => {
            this.toastr.success(type + " Deleted Successfully", "Success", { closeButton: true });
            this.getAllIncidents();
            AppComponent.hideLoader();
          },
          error => {
            if (error && error.error && error.error.message && error.error?.result)
              this.toastr.error(error.error.message + " " + error.error?.result[0], "Error", { closeButton: true });
            else if (error && error.error && error.error.message)
              this.toastr.error(error.error.message, "Error", { closeButton: true });
            else
              this.toastr.error("Something Went Wrong. Please contact Admin", "Error", { closeButton: true });

            this.getAllIncidents();
            AppComponent.hideLoader();
          }
        );
    }
  }


  openDeleteEventDialog(title, type, eventId) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure want to delete <b>' + title + '</b>?',
        eventId: eventId,
        eventType: type,
        eventTitle: title,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        },
      },
      panelClass: 'full-dialog-container',
      maxWidth:'70vw !important'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.isDelete) {
        this.deleteEventCall(result.eventId, result.eventType, result.eventTitle);
      }
    });
  }
}
