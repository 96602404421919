import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/api/user.service';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {
  UserData:any;
  userRolesFromDatabase: any[] = [
    { name: 'ReadWriteOnly' },
    { name: 'ReadOnly' },
    { name: 'Admin' },
  ];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  constructor(private userApi: UserService, private toastr: ToastrService,private router: Router,private _userService: UserService,private cookieService: CookieService) {}
  Loginuser:any;
  ngOnInit(): void {
    if (!this.isAdminRole()) {
      this.router.navigate(['/admin/events']);
    }
    this.getAllUsers();
    this.GetSearch();
  }

  getCookieValue(cookieName: string): string {
    return this.cookieService.get(cookieName);
  }


  HideLoginUser(){
    const myCookieValue = this.getCookieValue('loggedInUser');
    const parsedCookie = JSON.parse(myCookieValue);
    const userName = parsedCookie.username;
    this.Loginuser = userName;
  }


  GetSearch(){
    this.dtOptions = {
      lengthMenu: [
        [10, 25, 50, 100],
        [10, 25, 50, 100]
      ],
      ordering: true,
      serverSide: true,
      processing: false,
      destroy: true,
      columnDefs: [
        {
          "targets": [0, 2, 3, 4, 5, 6, 7],
          "orderable": false
        },{
        "name":"user",
        "targets": 1,
        "orderable": true
      }],
      ajax: (dataTablesParameters: any, callback) => {
        this.userApi.GetAllSearchUser(dataTablesParameters)
          .subscribe(
            data => {
              if (data && data.success && data.result) {
                var toteluser = data.result;
                this.UserData = data.result.list;
                console.log(data.result.list);
                callback({
                  recordsTotal: toteluser.recordsTotal,
                  recordsFiltered: toteluser.filteredCount,
                  data: [],
                });
                //this.reload();
              }
            },
            error => {
              console.log(error);
              callback({
                recordsTotal: this.UserData?.totalCount,
                recordsFiltered: this.UserData?.recordsTotal,
                data: []
              });
            }
          );
      },
    };

  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  getAllUsers(): void {
    this.userApi.GetAlluser().subscribe(
      (data: any) => {
        if (data.success && data.result) {
          this.UserData = data.result;
          this.HideLoginUser();
        } else {
          this.handleError('Failed to fetch user data');
        }
      },
      error => {
        this.handleError('An error occurred while fetching user data: ' + error);
      }
    );
  }

  toggleActive(user: any): void {
    const updatedUser = { ...user, isActive: user.isActive };
    this.updateUser(updatedUser, 'isActive');
  }

  togglerole(user: any): void {
    this.updateUser(user, 'role');
  }

  private updateUser(user: any, updateType: string): void {
    this.userApi.UpdateroleAndisActiveuser(user).subscribe(
      (data: any) => {
        if (data) {
          this.handleSuccess(`User ${updateType} updated successfully`);
        } else {
          this.handleError(`Failed to update ${updateType}`);
          // Revert the changes if the update fails
          if (updateType === 'isActive') {
            user.isActive = !user.isActive;
          }
        }
      },
      error => {
        this.handleError('An error occurred during update: ' + error);
        // Revert the changes if the update fails
        if (updateType === 'isActive') {
          user.isActive = !user.isActive;
        }
      }
    );
  }

  private handleSuccess(message: string): void {
    this.toastr.success(message, 'Success', { closeButton: true });
  }

 
  isAdminRole() {
    return this._userService.GetUserPermission("admin");
  }
  handleError(errorMessage: string): void {
    // Handle errors here
    console.error(errorMessage);
  }
}
