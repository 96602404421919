import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { sharedService } from './api/sharedService';
import { AdminApiService } from './api/adminapi.service';
import { CustomRoutesServiceService } from './api/CustomRoutesService.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'Status Page';
  public static loading: boolean = false;

  constructor(private cdref: ChangeDetectorRef,
     private sharedService: sharedService,
     private customRouterService:CustomRoutesServiceService,
     private router: Router,private cookieService: CookieService,
     private adminApiService: AdminApiService) {

  }
  ngOnInit() {
    this.sharedService.getTheme();
        // const themme = this.customRouterService.getTheme();
    // this.cookieService.set('th',themme);
    // this.adminApiService.GetSettings().subscribe((data) => {
    //   const details = data.result[0].currentTheme;
    //    this.cookieService.set('theme',details);

    //    this.cookieService.get(details);

    // });


    //if (currentTheme === 'Aurora') {
    //  this.router.navigate(['/home3']);
    //} else if (currentTheme === 'Bluelack') {
    //  this.router.navigate(['/home2']);
    //} else {
    //  this.router.navigate(['']);
    //}

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  public static showLoader() {
    if (AppComponent.loading != true) {
      AppComponent.loading = true;

      setTimeout(() => {
        AppComponent.hideLoader();
      }, 3000);
    }
  }

  public static hideLoader() {
    if (AppComponent.loading != false) {
      AppComponent.loading = false;
    }
  }

  getLoading() {
    return AppComponent.loading;
  }

  public static copyLink(val: string){
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = window.location.origin + val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
  }

}
