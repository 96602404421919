import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { sharedService } from '../../../api/sharedService';
import { StatusApiService } from '../../../api/statusapi.service';
import { AppComponent } from '../../../app.component';
import { Globals } from '../../../global';
import { Incident, StatusPageModel } from '../../../models/StatusPageModel';
import { EventDetailDialogComponent } from '../event-detail-dialog/event-detail-dialog.component';
import { AdminApiService } from 'src/app/api/adminapi.service';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
})

export class NavMenuComponent {
  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  displayNotificationBar: boolean = false;
  logoUrl: string;
  // logoUrl: any = 'assets/image/logodummy.svg';

  statusData: StatusPageModel;
  incidents: Incident[];
  maintenances: Incident[];
  activeAnnouncements: Incident[];
  totalNotifications: number = 0;
  isNotificationOpened: boolean = false;
  timeZone;
  isValidFormSubmitted = null;
  subscribeForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required]),
  });
  title: any;
  
  constructor(private _statusApiService: StatusApiService,
    private adminApiService: AdminApiService,
    private toastr: ToastrService, public router: Router,
    private dialog: MatDialog, private _sharedService: sharedService,
    private cookieService: CookieService
    ) {
  }

  getCookie(): void {
    const value = this.cookieService.get('iSetting');
    console.log(value);
    const cookieObject = JSON.parse(value);
    this.title = cookieObject.title;
    this.logoUrl = cookieObject.logoLink;
  }


  ngOnInit() {
    this.getCookie();
    this.addRecaptchaScript();
    this.timeZone = Globals.timeZone;
    this.getComponentStatus();
    this.getActiveAnnouncments();
      this.adminApiService.GetSettings().subscribe((data) => {
        console.log(data)
        const details = data.result[0];
         this.title = details?.title;
         this.logoUrl = details?.logoLink;
      });
  }

  ngAfterViewInit() {
  }

  get f() {
    return this.subscribeForm.controls;
  }

  resolved(event) {
    if (event)
      this.onFormSubmit();
  }

  onFormSubmit() {

    this.isValidFormSubmitted = false;
    if (this.subscribeForm.invalid) {
      AppComponent.hideLoader();
      return;
    }
    this.isValidFormSubmitted = true;

    const requestPayload = { subscriptionValue: this.f.email.value, subscriberType: 'email' };

    AppComponent.showLoader();
    this._statusApiService.AddUpdateSubscriber(requestPayload)
      .subscribe(
        data => {
          if (data.success && data.result) {
            window.location.href = '/subscription/' + data.result + '?newsubscribe=true';
          }

          setTimeout(() => {
            AppComponent.hideLoader();
          },200);
        },
        error => {
          if (error && error.error && error.error.message && error.error?.result)
            this.toastr.error(error.error?.result[0], "Error", { closeButton: true });
          else if (error && error.error && error.error.message)
            this.toastr.error(error.error.message, "Error", { closeButton: true });
          else
            this.toastr.error("Something Went Wrong. Please contact Admin", "Error", { closeButton: true });

          AppComponent.hideLoader();
        }
      );

  }

  validate(event) {
    window['grecaptcha'].execute();
  }


  renderReCaptch() {
    window['grecaptcha']?.render(document.getElementById('recaptcha'), {
      'sitekey': Globals.recaptchaSiteKey,
      'callback': (response) => {
        this.onFormSubmit();
        window['grecaptcha'].reset();
      },
      'size': 'invisible'
    });
  }

  addRecaptchaScript() {

    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }

    (function (d, s, id, obj) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }


  toggleNotificationBar() {
    this.isNotificationOpened = true;
    this.displayNotificationBar = !this.displayNotificationBar;
  }

  getComponentStatus() {
    this._statusApiService.GetComponentsStatus()
      .subscribe(
        data => {
          if (data && data.success && data.result && data.result.componentGroups
            && data.result.componentGroups.length > 0) {
            this.statusData = data.result;
            this.incidents = this.statusData.incidents;
            this.maintenances = this.statusData.maintenances;
            this.updateNotifications();
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  getActiveAnnouncments() {
    this._statusApiService.GetActiveAnnouncements()
      .subscribe(
        data => {
          if (data && data.success && data.result) {
            this.activeAnnouncements = data.result;
          }
          this.updateNotifications();
        },
        error => {
          console.log(error);
        }
      );
  }

  updateNotifications() {
    this.totalNotifications = this.activeAnnouncements?.length + this.incidents?.length + this.maintenances?.length;
  }

  openEventDetail(id) {
    const dialogRef = this.dialog.open(EventDetailDialogComponent,
      {
        panelClass: 'full-dialog',
        data: id,
        width: '70%',
        height: '60%',
        autoFocus: false,
      });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
