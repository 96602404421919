import { ChangeDetectorRef, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AdminApiService } from '../../../api/adminapi.service';
import { UserService } from '../../../api/user.service';
import { User } from '../../../models/UserModel';
import { Breadcrumb } from '../models/breadcrumb';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { AuthenticationService } from 'src/app/api/authentication.service';
import { Incident, StatusPageModel } from '../../../models/StatusPageModel';
import { StatusApiService } from '../../../api/statusapi.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { sharedService } from 'src/app/api/sharedService';
import { EventDetailDialogComponent } from '../../status/event-detail-dialog/event-detail-dialog.component';
import { Globals } from 'src/app/global';

@Component({
  selector: 'admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent {
  bcLoadedData;
  breadcrumbs$: Observable<Breadcrumb[]>;
  user: User;
  statusData: StatusPageModel;
  displayNotificationBar: boolean = false;
  totalNotifications: number = 0;
  isNotificationOpened: boolean = false;
  activeAnnouncements: Incident[];
  incidents: Incident[];
  timeZone;
  maintenances: Incident[];
  private _unsubscribeAll: Subject<any> = new Subject<any>();


  constructor(private _router: Router,
    private readonly _breadcrumbService: BreadcrumbService,
    private _adminApiService: AdminApiService,
    private _userService: UserService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _toastr: ToastrService,
    public router: Router,
    private _dialog: MatDialog,
    private _sharedService: sharedService,
    private authService: AuthenticationService,
    private _statusApiService: StatusApiService,) {

    this.breadcrumbs$ = _breadcrumbService.breadcrumbs$;
  }

  ngOnInit(): void {
    this.getActiveAnnouncments();
    this.getComponentStatus();
    this.timeZone = Globals.timeZone;
    this._userService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {

        this.user = user;
        this._changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  logout() {
    this._userService.removeUser();
    setTimeout(() => {
      window.location.href = "/Auth/Logout";
    },500);
    localStorage.removeItem('token');
  }
  toggleNotificationBar() {
    this.isNotificationOpened = true;
    this.displayNotificationBar = !this.displayNotificationBar;
  }
  getComponentStatus() {
    this._statusApiService.GetComponentsStatus()
      .subscribe(
        data => {
          if (data && data.success && data.result && data.result.componentGroups
            && data.result.componentGroups.length > 0) {
            this.statusData = data.result;
            this.incidents = this.statusData.incidents;
            this.maintenances = this.statusData.maintenances;
            this.updateNotifications();
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  getActiveAnnouncments() {
    this._statusApiService.GetActiveAnnouncements()
      .subscribe(
        data => {
          if (data && data.success && data.result) {
            this.activeAnnouncements = data.result;
          }
          this.updateNotifications();
        },
        error => {
          console.log(error);
        }
      );
  }

  updateNotifications() {
    this.totalNotifications = this.activeAnnouncements?.length + this.incidents?.length + this.maintenances?.length;
  }

  openEventDetail(id) {
    const dialogRef = this._dialog.open(EventDetailDialogComponent,
      {
        panelClass: 'full-dialog',
        data: id,
        width: '70%',
        height: '60%',
        autoFocus: false,
      });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
