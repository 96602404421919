import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { AdminApiService } from 'src/app/api/adminapi.service';

@Component({
  selector: 'app-ping-history',
  templateUrl: './uptime-history.component.html',
  styleUrls: ['./uptime-history.component.css']
})
export class uptimeHistoryComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  uptimehistorylistData: any = [];

  constructor(private route: ActivatedRoute,private _adminApiService: AdminApiService) { 

  }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this._adminApiService.uptimehistory(id).subscribe((data) =>{
      this.uptimehistorylistData = data.result;
      console.log(data.result)
  })
  }

}
