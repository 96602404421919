import { Breadcrumb } from './models/breadcrumb';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { AdminNavMenuComponent } from './admin-nav-menu/admin-nav-menu.component';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AdminFooterComponent } from './admin-footer/admin-footer.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { AdminApiService } from '../../api/adminapi.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { SettingsComponent } from './settings/settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DataTablesModule } from 'angular-datatables';
import { IncidentComponent } from './incident/incident.component';
import { AuthInterceptorInterceptor } from 'src/app/Auth/auth-interceptor.interceptor';
import { AuthInterceptor } from 'src/app/interceptor/AuthInterceptor';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { FeatureRequestmodalsComponent } from './feature-requestmodals/feature-requestmodals.component';
import { uptimeComponent } from './uptime/uptime.component';
import { uptimeAddComponent } from './uptime/uptime-add/uptime-add.component';
import { uptimeHistoryComponent } from './uptime/uptime-history/uptime-history.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';


const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '', redirectTo: 'events', pathMatch: 'full',
        data: { breadcrumb: '' },
      },
      {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
        data: { breadcrumb: 'Events' },
      },
      {
        path: 'components',
        loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule),
        data: { breadcrumb: 'Components' },
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        data: { breadcrumb: 'Reports' },
      },
      {
        path: 'subscribers',
        loadChildren: () => import('./subscribers/subscribers.module').then(m => m.SubscribersModule),
        data: { breadcrumb: 'Subscribers' },
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        data: { breadcrumb: 'Settings' },
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { breadcrumb: 'Dashboard' },
      },
      {
        path: 'incident',
        loadChildren: () => import('./incident/incident.module').then(m => m.IncidentModule),
        data: { breadcrumb: 'Cases' },
      },
      {
        path: 'uptime',
        loadChildren: () => import('./uptime/uptime.module').then(m => m.uptimeModule),
        data: { breadcrumb: 'uptime' },
      },
      {
        path: 'user',
        component:ManageUserComponent,
        data: { breadcrumb: 'User ' },
      }
    ]
  },
];

@NgModule({
  declarations: [
    AdminComponent,
    AdminNavMenuComponent,
    AdminHeaderComponent,
    AdminFooterComponent,
    ConfirmationDialogComponent,
    SettingsComponent,
    DashboardComponent,
    IncidentComponent,
    ManageUserComponent,
    FeatureRequestmodalsComponent,
    uptimeComponent,
    uptimeAddComponent,
    uptimeHistoryComponent,
  ],
  imports: [
    CommonModule,
    NgxTippyModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    ImageCropperModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DataTablesModule,
    HttpClientModule,
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    NgxSliderModule,
  ],
  providers: [
    AdminApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true,
    }
  ]
})
export class AdminModule { }
